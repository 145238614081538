import { images } from '.';

const blogs = [

    {
        id: 1,
        Image: images.blog1,
        category: 'Design',
        duration: 4,
        title: 'Blog-1',
        content: 'Discover the latest trends in web design that are shaping the digital landscape in 2022.',
        date: '5th',
        month: 'Feb',
        year: '2022',
        important: 'Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences. Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences.',
        paragraph: {
            para1: 'Web design is constantly evolving with technology advancements and changing user preferences. In 2022, designers are focusing more on creating intuitive, visually appealing, and accessible websites.',
            para2: 'Trends like dark mode, bold typography, and immersive animations are increasingly popular. These elements not only enhance the aesthetic appeal but also improve user engagement and accessibility.',
            para3: 'Another significant trend is the shift towards minimalism and functional design. Simplicity, combined with strategic use of white space, guides the user experience and highlights essential content effectively.',
        },
        subSection1: {
            subHeading: 'Heading1',
            detail1: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
            detail2: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
        subSection2: {
            subHeading: 'heading2',
            details: 'Below are some of the leading trends in web design that are redefining the online experience this year.',
            points: [ 
                {id: 1, title: 'title1', details:'Below are some of the leading trends in web design that are redefining the online experience this year. Below are some of the leading trends in web design that are redefining the online experience this year.'},
                {id: 2, title: 'title2', details:'Below are some of the leading trends in web design that are redefining the online experience this year.'},
            ],
        },
        subSection3: {
            subHeading: 'Heading3',
            details: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
    },
    {
        id: 2,
        Image: images.blog2,
        category: 'Design',
        duration: 4,
        title: 'Blog-2',
        content: 'Discover the latest trends in web design that are shaping the digital landscape in 2022.',
        date: '5th',
        month: 'Feb',
        year: '2022',
        important: 'Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences. Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences.',
        paragraph: {
            para1: 'Web design is constantly evolving with technology advancements and changing user preferences. In 2022, designers are focusing more on creating intuitive, visually appealing, and accessible websites.',
            para2: 'Trends like dark mode, bold typography, and immersive animations are increasingly popular. These elements not only enhance the aesthetic appeal but also improve user engagement and accessibility.',
            para3: 'Another significant trend is the shift towards minimalism and functional design. Simplicity, combined with strategic use of white space, guides the user experience and highlights essential content effectively.',
        },
        subSection1: {
            subHeading: 'Heading1',
            detail1: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
            detail2: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
        subSection2: {
            subHeading: 'heading2',
            details: 'Below are some of the leading trends in web design that are redefining the online experience this year.',
            points: [ 
                {id: 1, title: 'title1', details:'Below are some of the leading trends in web design that are redefining the online experience this year. Below are some of the leading trends in web design that are redefining the online experience this year.'},
                {id: 2, title: 'title2', details:'Below are some of the leading trends in web design that are redefining the online experience this year.'},
            ],
        },
        subSection3: {
            subHeading: 'Heading3',
            details: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
    },
    {
        id: 3,
        Image: images.blog3,
        category: 'Design',
        duration: 4,
        title: 'Blog-3',
        content: 'Discover the latest trends in web design that are shaping the digital landscape in 2022.',
        date: '5th',
        month: 'Feb',
        year: '2022',
        important: 'Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences. Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences.',
        paragraph: {
            para1: 'Web design is constantly evolving with technology advancements and changing user preferences. In 2022, designers are focusing more on creating intuitive, visually appealing, and accessible websites.',
            para2: 'Trends like dark mode, bold typography, and immersive animations are increasingly popular. These elements not only enhance the aesthetic appeal but also improve user engagement and accessibility.',
            para3: 'Another significant trend is the shift towards minimalism and functional design. Simplicity, combined with strategic use of white space, guides the user experience and highlights essential content effectively.',
        },
        subSection1: {
            subHeading: 'Heading1',
            detail1: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
            detail2: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
        subSection2: {
            subHeading: 'heading2',
            details: 'Below are some of the leading trends in web design that are redefining the online experience this year.',
            points: [ 
                {id: 1, title: 'title1', details:'Below are some of the leading trends in web design that are redefining the online experience this year. Below are some of the leading trends in web design that are redefining the online experience this year.'},
                {id: 2, title: 'title2', details:'Below are some of the leading trends in web design that are redefining the online experience this year.'},
            ],
        },
        subSection3: {
            subHeading: 'Heading3',
            details: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
    },
    {
        id: 1,
        Image: images.blog4,
        category: 'Design',
        duration: 4,
        title: 'Blog-4',
        content: 'Discover the latest trends in web design that are shaping the digital landscape in 2022.',
        date: '5th',
        month: 'Feb',
        year: '2022',
        important: 'Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences. Stay ahead in the industry by exploring the new trends in web design, from minimalism to interactive experiences, that are captivating audiences.',
        paragraph: {
            para1: 'Web design is constantly evolving with technology advancements and changing user preferences. In 2022, designers are focusing more on creating intuitive, visually appealing, and accessible websites.',
            para2: 'Trends like dark mode, bold typography, and immersive animations are increasingly popular. These elements not only enhance the aesthetic appeal but also improve user engagement and accessibility.',
            para3: 'Another significant trend is the shift towards minimalism and functional design. Simplicity, combined with strategic use of white space, guides the user experience and highlights essential content effectively.',
        },
        subSection1: {
            subHeading: 'Heading1',
            detail1: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
            detail2: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
        subSection2: {
            subHeading: 'heading2',
            details: 'Below are some of the leading trends in web design that are redefining the online experience this year.',
            points: [ 
                {id: 1, title: 'title1', details:'Below are some of the leading trends in web design that are redefining the online experience this year. Below are some of the leading trends in web design that are redefining the online experience this year.'},
                {id: 2, title: 'title2', details:'Below are some of the leading trends in web design that are redefining the online experience this year.'},
            ],
        },
        subSection3: {
            subHeading: 'Heading3',
            details: 'Keeping up with design trends can help your website stay competitive, appealing, and user-friendly. Trends like responsive design and accessibility-first approaches enhance the overall experience for all users.',
        },
    },

];

export default blogs;