import React from 'react';
import { Sidebar } from './components';
import { About, Blogs, BlogSingle, Contact, Home, Portfolio, Services, Testimonials } from './sections';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


const App = () => {
  return (
    <div className="App">
      <Sidebar />

      <Routes>
        
        {/*Home*/}
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Portfolio' element={<Portfolio />} />
        <Route path='/Testimonials' element={<Testimonials />} />
        <Route path='/Blogs' element={<Blogs />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Blogs/:id' element={<BlogSingle  />} />
      </Routes>

    </div>
  )
}

export default App;