import React from 'react';
import './Home.css';

import { Button } from '../../components';

import Typed from 'react-typed';

const Home = () => {

  const fields = [
    'web developer',
    'graphic designer',
    'photohrapher',
    'content writer'
  ];

  return (

    <section className='home' id='home'> 

      <div className='box-container'>
  
        <div className='content'>
          <h1>hello! I'm Serena</h1>
          <h4><Typed strings={fields} typeSpeed={60} backSpeed={50} loop /></h4>
          <p>Creative software engineer offering 6+ years of experience providing high-impact web solutions for many different organizations.</p>
          <Button link={'/Contact'} title={'contact me'} />
        </div>

      </div> 

    </section>

  )
}

export default Home