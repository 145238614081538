import portfolio1 from '../assets/Portfolio/Portfolio-1.png';
import portfolio2 from '../assets/Portfolio/Portfolio-2.png';
import portfolio3 from '../assets/Portfolio/Portfolio-3.png';
import portfolio4 from '../assets/Portfolio/Portfolio-4.png';
import portfolio5 from '../assets/Portfolio/Portfolio-5.png';
import portfolio6 from '../assets/Portfolio/Portfolio-6.png';
import portfolio7 from '../assets/Portfolio/Portfolio-7.png';
import portfolio8 from '../assets/Portfolio/Portfolio-8.png';
import portfolio9 from '../assets/Portfolio/Portfolio-9.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';

import blog1 from '../assets/Blogs/Blog-1.png';
import blog2 from '../assets/Blogs/Blog-2.png';
import blog3 from '../assets/Blogs/Blog-3.png';
import blog4 from '../assets/Blogs/Blog-4.png';

import blog1gallery1 from '../assets/Blog-Gallery/1.png';
import blog1gallery2 from '../assets/Blog-Gallery/2.png';


const Images = {

    portfolio1,
    portfolio2,
    portfolio3,
    portfolio4,
    portfolio5,
    portfolio6,
    portfolio7,
    portfolio8,
    portfolio9,

    testimonial1,
    testimonial2,
    testimonial3,

    blog1,
    blog2,
    blog3,
    blog4,

    blog1gallery1,
    blog1gallery2,
};

export default Images;