import React from 'react'
import './Blogs.css';

import { Heading } from '../../components';
import { blogs } from '../../constants';

import { Link } from 'react-router-dom'; 

 
const Blogs = () => {   
  return (
    <section className='blog' id='blog'>

      <Heading title={'my blogs'} span={'latest news'} />
 
      <div className='box-container'>
        {
          blogs.map((blog) => { 
            return (
            <div className='blog-item' key={blog.id}>

              <div className='image'>
                <span className='date'>{`${blog.date} ${blog.month}, ${blog.year}`}</span>
                <img src={blog.Image} alt='Blog' />
              </div> 
            
              <div className='content'>
                <div className='intro'>
                  <div className='category'>{blog.category}</div>
                  <span>{blog.duration} min read</span>
                </div>
                <Link to={{ 
                    pathname: '/Blogs/' + blog.title,
                  }} 
                  location = {{blog}}
                  state= {blog}
                  className='main-heading'>{blog.title}
                </Link>
                <p>{blog.content}</p>    
              </div>

            </div>
            )
          })
        }
      </div>

    </section>
  )
}

export default Blogs